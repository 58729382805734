import React, { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './App.css';
import { useSelector } from 'react-redux';

import Loader from './Components/Loader';

const Home = lazy(() => import('./Pages/Home/Home'));
const Organization = lazy(() => import('./Pages/Auth/Organization/Organization'));
const Login = lazy(() => import('./Pages/Auth/Login/Login'));
const Signup = lazy(() => import('./Pages/Auth/Signup/Signup'));
const AppDrawerNav = lazy(() => import('./Pages/CreateNewBot/AppDrawerNav'));
const ChatInterface = lazy(() => import('./Pages/HtmlEmbeds/ChatInterface'));


function App() {
  const [combinations, setCombinations] = useState([]);
  const [advancedMode, setAdvancedMode] = useState(true);
  const chatbot = useSelector(state => state.chatbot)
  const [selected_combinations, set_selected_combinations] = useState(chatbot?.selected_combinations || []);
  const [subscriptionPackage, setSubscriptionPackage] = useState(null)


  const [botData, setBotData] = useState(
    {
      organization: "",
      creator: "",
      name: "first app",
      problem_statement: "",
      "llm-finetuning": false,
      datasets: [],
      structured_datasets: [],
      finetuning_dataset: [],
      dynamicShot_dataset: [],
      rqa_prompt: ``,
      prompt_combinations: {},
      keys: {},
      parameter_key_names: {
        chunksize: { "300": true, "700": false, "1800": false, "4200": false },
        metric: { cosine: true, dotproduct: false, euclidean: false, hybrid: false },
        retrieval_method: { basic: true, "sentence-window": false, "multi-query": false, "hyde": false, "complex-to-simple-query": false },
        rerank_method: { "none": true, mmr: false, "cohere": false, "llm_rerank": false },
        top_k: { "1": true, "5": false, "10": false, "15": false, "20": false },

        embedding_model: { "text-embedding-ada-002": true, "fine-tuned": false },
        metadata_filtering: { "add-metadata": false, "no-metadata": true },
        applications: { "no-application": true, "slack": false, "telegram": false, "googledrive": false },
        tools: { "no-tools": true, "calendly": false, "traditionalDb": false, "payment-system": false },
        knowledgebase: { "no-knowledgebase": true, "csv": false, "websites": false, "drive": false }

      },
      golden_responses: [],
      optimization_method: 'grid-search',
      have_memory: false,
      response_length: { "detailed-answers": false, "concise": false, "no-preference": true },
      response_length_text: "",
      recency: false,
      recency_text: "",
      tone: { "formal": false, "informal": false, "no-preference": true },
      tone_text: "",
      latency: { "critical": true, "noCritical": false },
      references: false,
      references_text: "",
      sources: false,
      sources_text: "",
      metadata_filtering_sub: { "all-document-types": false, "one-document-type": true },
      metadata_filtering_categories: { "all-equal": true, "not-equal": false },
      metadata_categories: [],
      structured_file: null,
      finetuning_values: {
        llm: { "llama-3-8b": true, "gpt-3.5": false },
        "epoch": { 1: true },
        "learningRate": { 0.0001: "true" },
        "batchSize": { 1: "true" },
        "alphaRank": { 1: "true" },
        "rank": { 1: "true" },
        'optimization-method': 'grid-search',
      },
      llm_params: {
        temperature: { 0: "true" },
        outputTokenLimit: { 0: "true" },
        frequencyPenalty: { 0: "true" },
        presencePenalty: { 0: "true" },
        llm: { "gpt-4o-mini": true, "gpt-4": false, "gpt-4-turbo-2024-04-09": false, "llama-7b-chat": false, "llm-finetuning": false },
        prompts: {
          "zero-shot": true, "few-shot": false, "few-shot-random": false,
          "dynamic-few-shot": false, "chain-of-thought": false, "few-shot-cot-random": false
        }

      },
      workflow: {},

    }

  )

  // data = {retrieval_0: {}, generation_1:{llm_params: {}, finetuning_values: {}}, toolname_index: <id>}

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/add/organization" element={<Organization />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route
            path="/dashboard/*"
            element={
              <AppDrawerNav
                botData={botData}
                setBotData={setBotData}
                advancedMode={advancedMode}
                setAdvancedMode={setAdvancedMode}
                combinations={combinations}
                setCombinations={setCombinations}
                selected_combinations={selected_combinations}
                set_selected_combinations={set_selected_combinations}
                subscriptionPackage={subscriptionPackage}
                setSubscriptionPackage={setSubscriptionPackage}
              />
            }
          />
          <Route path="/chat-interface" element={<ChatInterface />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
