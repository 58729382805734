import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orgService from "./orgService";
//get user from local storage
const organization = JSON.parse(localStorage.getItem("organization"));
// const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  organization: organization ? organization : null,
  users: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//register user

export const registerOrg = createAsyncThunk(
  "organization/registerOrganization",
  async (orgData, thunkAPI) => {
    try {
      return await orgService.registerOrg(orgData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//register user

export const addOwner = createAsyncThunk(
  "organization/addOwner",
  async (organization, thunkAPI) => {
    try {
      return await orgService.addOwner(organization);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update updateOrganizationDetails

export const updateOrganizationDetails = createAsyncThunk(
  "organization/updateAccountDetails",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.updateOrganizationDetails({
        orgData,
      });
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get getOrganization
export const getOrganization = createAsyncThunk(
  "organization/getOrganizationByUser",
  async (id, thunkAPI) => {
    try {
      const response = await orgService.getOrganization(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get getOrganization
export const get_organization_by_user = createAsyncThunk(
  "organization/get_organization_by_user",
  async (id, thunkAPI) => {
    try {
      const response = await orgService.get_organization_by_user(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get add new user

export const addNewUser = createAsyncThunk(
  "organization/addNewUser",
  async (userdata, thunkAPI) => {
    try {
      const response = await orgService.addNewUser(userdata);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add users batch to organization

export const addMultipleUsers = createAsyncThunk(
  "organization/addMultipleUsers",
  async (userdata, thunkAPI) => {
    try {
      const response = await orgService.addMultipleUsers(userdata);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//getAllUsers

export const getAllUsers = createAsyncThunk(
  "organization/getAllUsers",
  async (data, thunkAPI) => {
    try {
      console.log("data", data);
      const response = await orgService.getAllUsers(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//updateUser

export const updateUser = createAsyncThunk(
  "organization/updateUser",
  async (data, thunkAPI) => {
    try {
      const response = await orgService.updateUser(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//deleteUser
export const deleteUser = createAsyncThunk(
  "organization/deleteUser",
  async (user, thunkAPI) => {
    try {
      const response = await orgService.deleteUser(user);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//testUserKyes
export const testUserKyes = createAsyncThunk(
  "organization/testUserKyes",
  async (user, thunkAPI) => {
    try {
      const response = await orgService.testUserKyes(user);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orgSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    adduser: (state, action) => {
      const newUsers = [...state.organization.users, action.payload];
      state.organization.users = newUsers;
    },
    resetOrganizationComplete: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      localStorage.clear();
    },
    resetOgranization: (state) => {
      state.organization = null;
    },
    modifyKeys: (state, action) => {
      state.organization = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerOrg.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerOrg.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
        // state.token = action.payload.token;
      })
      .addCase(registerOrg.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.organization = null;
      })
      .addCase(addOwner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOwner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        // state.token = action.payload.token;
      })
      .addCase(addOwner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateOrganizationDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrganizationDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization.name = action.payload.name;
      })
      .addCase(updateOrganizationDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(get_organization_by_user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_organization_by_user.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(get_organization_by_user.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addNewUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.status;
        state.users = [...state.users, action.payload.user];
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addMultipleUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMultipleUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Users added successfully";
        console.log(action.payload);
        // Merge new users into the existing users list
        state.users = [...state.users, ...action.payload.users];
      })
      .addCase(addMultipleUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.status;
        console.log(action.payload);
        const updatedUser = action.payload.user;
        const email = action.payload.user.email;
        state.users = state.users.map((user) =>
          user.email === email ? { ...user, ...updatedUser } : user
        );
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.users = [];
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(testUserKyes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(testUserKyes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.keys = null;
      })
      .addCase(testUserKyes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.keys = action.payload;
      });
  },
});
export const {
  resetOgranization,
  resetOrganizationComplete,
  adduser,
  modifyKeys,
} = orgSlice.actions;
export default orgSlice.reducer;
