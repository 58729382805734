import axios from "axios";

// user api
const API = axios.create({
  baseURL: "/api/user",
});

// developer api
const DevAPI = axios.create({
  baseURL: "/api/developer",
});

//register
const register = async (userData) => {
  const response = await API.post("/register", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("token", JSON.stringify(response.data.token));
    const result = {
      user: response.data,
    };
    return result;
  } else {
    return response.data;
  }
};

//Update password
const updateAccountDetails = async ({ userDetails, token }) => {
  const userData = {
    password: userDetails.newPassword,
    oldPassword: userDetails.oldPassword,
  };
  const user_id = userDetails.user_id;
  console.log("Token: ", token);
  console.log("UserData: ", userData);

  try {
    const response = await API.post(`edit_password/${user_id}`, userData);
    return response.data;
  } catch (error) {
    return error;
  }
};

// dev function to update user privilige level -> change to -> update user details
const updateUserPersonal = async ({ data, token }) => {
  const user_id = data.user_id;
  const userDetails = data.userDetails; // Ensure you have the member ID

  try {
    const response = await API.post(`edit/${user_id}`, userDetails, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the headers if required
      },
    });
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : { error: "Network Error" };
  }
};

const getUser = async (id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await API.get(`/${id}`, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

const login = async (user) => {
  const { email, password } = user;
  const response = await API.post(
    "/login",
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );

  localStorage.setItem("user", JSON.stringify(response.data));
  localStorage.setItem("token", JSON.stringify(response.data.token));

  const result = {
    user: response.data,
    token: response.data.token,
  };
  return result;
};

// get_usage
const get_usage = async (user) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.get(`/usage/${user}`, config);

  return response.data;
};

// generate api key
const generateApi = async (data) => {
  const { orgId, bot_id } = data;
  const token = JSON.parse(localStorage.getItem("token"));
  console.log({ orgId, bot_id });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await DevAPI.get(`/generate/${orgId}/${bot_id}`, config);

  return response.data;
};

// delete api key
const deleteApi = async (key) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await DevAPI.delete(`/delete`, { apiKey: key }, config);
  return response.data;
};

const authService = {
  register,
  login,
  getUser,
  updateAccountDetails,
  updateUserPersonal,
  get_usage,
  generateApi,
  deleteApi,
};

export default authService;
