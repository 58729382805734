import axios from "axios";

const API = axios.create({
  baseURL: "/api/organization",
});

const UserAPI = axios.create({
  baseURL: "/api/user",
});

//register
const registerOrg = async (orgData) => {
  const data = { name: orgData };
  const response = await API.post("/create", data);
  localStorage.setItem("organization", JSON.stringify(response.data));
  return response.data;
};

// add owner
const addOwner = async (orgData) => {
  const { organization, user_id } = orgData;
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/addOwner/${organization}`,
    { owner: user_id },
    config
  );
  return response.data;
};

// get organization
const getOrganization = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await API.get(`/${id}`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching organization:", error);
    return null; // Handle the error as needed
  }
};

// Get Organization by user
const get_organization_by_user = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await API.get(`/byuser/${id}`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching organization:", error);
    return null; // Handle the error as needed
  }
};

// Add new user
const addNewUser = async (userdata, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const user = userdata;
  const org_id = userdata.organization;

  console.log("USER: ", user);
  try {
    const response = await axios.post(
      `/api/user/adduser/${org_id}`,
      user,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error adding user:", error);
    return error.message; // Handle the error as needed
  }
};

// add multiple users
const addMultipleUsers = async (usersdata, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  console.log("userdata", usersdata);
  const data = usersdata.users;
  const org_id = usersdata.organization;

  console.log("data: ", data);
  try {
    const response = await axios.put(
      `/api/organization/addBatchUsers/${org_id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error adding user:", error);
    return error.message; // Handle the error as needed
  }
};

// Update user
const updateUser = async (data) => {
  try {
    const response = await axios.post(
      `/api/user/edit_by_email/${data.user_id}/${data.member_email}`,
      { userData: data.userData }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    return error; // Handle the error as needed
  }
};

//get all users
const getAllUsers = async (data) => {
  try {
    const response = await UserAPI.post(`/get_multiple_users`, { users: data });
    return response.data.users;
  } catch (error) {
    console.error("Error getting users:", error);
    return error; // Handle the error as needed
  }
};

const deleteUser = async (user, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `/api/user/delete_by_email/${user.email}`,
      { data: user.email },
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error adding user:", error);
    return error; // Handle the error as needed
  }
};

const updateOrganizationDetails = async ({ orgData, token }) => {
  try {
    const response = await API.put("/users/updateOrganizationDetails", orgData);
    return response.data;
  } catch (error) {
    return error;
  }
};

// test user keys
const testUserKyes = async (keys) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await API.get(`/test-keys/`, keys, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching organization:", error);
    return null; // Handle the error as needed
  }
};

const orgService = {
  registerOrg,
  updateOrganizationDetails,
  getOrganization,
  get_organization_by_user,
  addNewUser,
  updateUser,
  deleteUser,
  getAllUsers,
  addOwner,
  testUserKyes,
  addMultipleUsers,
};

export default orgService;
